<template>
    <div class="bg">
        <v-container class="pt-4 mt-0">
            <v-row>
                <v-col cols="12">
                    <h1 style="padding-top:0; text-align: center;">YouGlish</h1>
                    <v-card>
                        <v-card-text>
                            <iframe src="https://excellentglobal-api-whiyyqnxmq-rj.a.run.app/widget/youglish.html" style="width: 100%;height: 100vh; overflow:hidden;display:block; border: 0;" ></iframe>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>

export default {
    name: "YouGlish"
}
</script>

<style scoped>
    h1, h2 {color: #005FAB;}
    h1 {text-align: left; width: 100%; padding-top:50px;  font-weight: lighter; font-size: 40px;}
    h2 {font-size: 20px;}
    .bg {background-image: url("~@/assets/img/bg-tradutor.png"); background-repeat: no-repeat; background-position-x: -50px; background-position-y: -50px;}
</style>